<template>
     <div class="body">
          <div class="container">
               <div class="section-title-3">Careers</div>
               <div class="section-header-2">Healthcare</div>

               <div class="content">
                    <div class="list">
                         <div class="section-title-3">
                              Human Resources
                              <button class="button plusbtn" @click="toggleOpen(0)">
                                   <svg
                                        id="Group_40"
                                        data-name="Group 40"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10.675"
                                        height="10.675"
                                        viewBox="0 0 10.675 10.675"
                                   >
                                        <line id="Line_27" data-name="Line 27" x2="10.675" transform="translate(0 5.337)" stroke-width="0.5" />
                                        <line
                                             v-if="!show[0]"
                                             id="Line_28"
                                             data-name="Line 28"
                                             x1="10.675"
                                             transform="translate(5.337 0) rotate(90)"
                                             stroke-width="0.5"
                                        />
                                   </svg>
                              </button>
                         </div>

                         <div class="line-bottom">
                              <transition name="fade">
                                   <ul v-if="show[0]" class="square-icon">
                                        <li>Receptionist</li>
                                        <li>HR Associate</li>
                                        <li>Product Trainer - Kochi Office</li>
                                   </ul></transition
                              >
                         </div>
                    </div>
                    <div class="list">
                         <div class="section-title-3">
                              Finance
                              <button class="button plusbtn" @click="toggleOpen(1)">
                                   <svg
                                        id="Group_40"
                                        data-name="Group 40"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10.675"
                                        height="10.675"
                                        viewBox="0 0 10.675 10.675"
                                   >
                                        <line id="Line_27" data-name="Line 27" x2="10.675" transform="translate(0 5.337)" stroke-width="0.5" />
                                        <line
                                             v-if="!show[1]"
                                             id="Line_28"
                                             data-name="Line 28"
                                             x1="10.675"
                                             transform="translate(5.337 0) rotate(90)"
                                             stroke-width="0.5"
                                        />
                                   </svg>
                              </button>
                         </div>

                         <div>
                              <transition name="fade">
                                   <ul v-if="show[1]" class="square-icon">
                                        <li>Finance Manager - Mumbai Office</li>
                                        <li>Financial analyst - Kochi Office</li>
                                   </ul>
                              </transition>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     export default {
          data() {
               return {
                    show: [true, true],
               };
          },
          methods: {
               toggleOpen(index) {
                    this.show.splice(index, 1, !this.show[index]);
               },
          },
     };
</script>

<style lang="scss" scoped>
     // @import '@/assets/styles/variables.scss';

     .body {
          padding: 60px 0;
          @media screen and (max-width: 1023px) {
               padding: 20px 0 50px;
          }
     }
     .section-header-2 {
          font-weight: 300;
          margin-top: 10px;
          margin-bottom: 15px;
     }
     .section-title-3 {
          margin-bottom: 10px;
     }
     .plusbtn {
          position: relative;
          top: 5px;
          margin-left: 10px;
     }
     .list {
          max-width: 800px;
          margin-top: 50px;
     }
     ul {
          padding: 20px 30px 40px;
     }
     .line-bottom {
          min-height: 50px;
     }
</style>
